import { __awaiter, __generator } from "tslib";
import io from 'socket.io-client';
import { dispatch } from 'codesandbox-api';
import _debug from 'debug';
import axios from 'axios';
var debug = _debug('executors:server');
function sseTerminalMessage(msg) {
    dispatch({
        type: 'terminal:message',
        data: "> Sandbox Container: ".concat(msg, "\n\r"),
    });
}
/**
 * Find the changes from the last run, we only work with saved code here.
 */
var getDiff = function (oldFiles, newFiles) {
    var diff = {};
    Object.keys(newFiles)
        .filter(function (p) {
        var newSavedCode = newFiles[p].savedCode || newFiles[p].code;
        if (oldFiles[p]) {
            var oldSavedCode = oldFiles[p].savedCode || oldFiles[p].code;
            if (oldSavedCode !== newSavedCode) {
                return true;
            }
        }
        else {
            return true;
        }
        return false;
    })
        .forEach(function (p) {
        diff[p] = {
            code: newFiles[p].code,
            path: newFiles[p].path,
            savedCode: newFiles[p].savedCode,
            isBinary: newFiles[p].isBinary,
        };
    });
    Object.keys(oldFiles).forEach(function (p) {
        if (!newFiles[p]) {
            diff[p] = {
                path: oldFiles[p].path,
                isBinary: false,
                code: null,
                savedCode: null,
            };
        }
    });
    return diff;
};
var MAX_SSE_AGE = 24 * 60 * 60 * 1000; // 1 day
var tick = function () { return new Promise(function (r) { return setTimeout(function () { return r(); }, 0); }); };
var ServerExecutor = /** @class */ (function () {
    function ServerExecutor() {
        this.connectTimeout = null;
        this.token = this.retrieveSSEToken();
    }
    ServerExecutor.prototype.initializeSocket = function () {
        return __awaiter(this, void 0, void 0, function () {
            var usedHost, sseLbHost, res, sseHost;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.sandboxId) {
                            throw new Error('initializeSocket: sandboxId is not defined');
                        }
                        usedHost = this.host || 'https://codesandbox.stream';
                        sseLbHost = usedHost.replace('https://', 'https://sse-lb.');
                        return [4 /*yield*/, axios.get("".concat(sseLbHost, "/api/cluster/").concat(this.sandboxId))];
                    case 1:
                        res = _a.sent();
                        sseHost = res.data.hostname;
                        this.socket = io(sseHost, {
                            autoConnect: false,
                            transports: ['websocket', 'polling'],
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ServerExecutor.prototype.initialize = function (_a) {
        var _b;
        var sandboxId = _a.sandboxId, files = _a.files, host = _a.host;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this.sandboxId === sandboxId && ((_b = this.socket) === null || _b === void 0 ? void 0 : _b.connected)) {
                            return [2 /*return*/];
                        }
                        this.host = host;
                        this.sandboxId = sandboxId;
                        this.lastSent = files;
                        return [4 /*yield*/, this.dispose()];
                    case 1:
                        _c.sent();
                        return [4 /*yield*/, tick()];
                    case 2:
                        _c.sent();
                        return [4 /*yield*/, this.initializeSocket()];
                    case 3:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ServerExecutor.prototype.setup = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                debug('Setting up server executor...');
                return [2 /*return*/, this.openSocket()];
            });
        });
    };
    ServerExecutor.prototype.dispose = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.socket) {
                    this.socket.removeAllListeners();
                    this.socket.close();
                }
                return [2 /*return*/];
            });
        });
    };
    ServerExecutor.prototype.updateFiles = function (newFiles) {
        var changedFiles = this.lastSent
            ? getDiff(this.lastSent, newFiles)
            : newFiles;
        this.lastSent = newFiles;
        if (Object.keys(changedFiles).length > 0 && this.socket) {
            debug(Object.keys(changedFiles).length + ' files changed, sending to SSE.');
            debug(changedFiles);
            this.socket.emit('sandbox:update', changedFiles);
        }
    };
    ServerExecutor.prototype.emit = function (event, data) {
        if (this.socket) {
            this.socket.emit(event, data);
        }
    };
    ServerExecutor.prototype.on = function (event, listener) {
        if (this.socket) {
            this.socket.on(event, listener);
        }
    };
    ServerExecutor.prototype.openSocket = function () {
        var _this = this;
        var _a;
        if ((_a = this.socket) === null || _a === void 0 ? void 0 : _a.connected) {
            return Promise.resolve();
        }
        return new Promise(function (resolve, reject) {
            _this.socket.on('connect', function () { return __awaiter(_this, void 0, void 0, function () {
                var e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            if (this.connectTimeout) {
                                clearTimeout(this.connectTimeout);
                                this.connectTimeout = null;
                            }
                            return [4 /*yield*/, this.startSandbox()];
                        case 1:
                            _a.sent();
                            resolve();
                            return [3 /*break*/, 3];
                        case 2:
                            e_1 = _a.sent();
                            debug('Error connecting to SSE manager: ', e_1);
                            reject(e_1);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); });
            _this.socket.on('sandbox:start', function () {
                sseTerminalMessage("Sandbox ".concat(_this.sandboxId, " started"));
            });
            if (_this.socket) {
                _this.socket.open();
            }
        });
    };
    ServerExecutor.prototype.startSandbox = function () {
        return __awaiter(this, void 0, void 0, function () {
            var token;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.token];
                    case 1:
                        token = _a.sent();
                        if (this.socket) {
                            this.socket.emit('sandbox', { id: this.sandboxId, token: token });
                        }
                        debug('Connected to sse manager, sending start signal...');
                        sseTerminalMessage("Starting sandbox ".concat(this.sandboxId, "..."));
                        if (this.socket) {
                            this.socket.emit('sandbox:start');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ServerExecutor.prototype.retrieveSSEToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            var existingKey, currentTime, parsedKey, devJwt, headers;
            return __generator(this, function (_a) {
                debug('Retrieving SSE token...');
                existingKey = localStorage.getItem('sse');
                currentTime = new Date().getTime();
                if (existingKey) {
                    parsedKey = JSON.parse(existingKey);
                    if (parsedKey.key && currentTime - parsedKey.timestamp < MAX_SSE_AGE) {
                        debug('Retrieved SSE token from cache');
                        return [2 /*return*/, parsedKey.key];
                    }
                }
                devJwt = localStorage.getItem('devJwt');
                headers = {
                    'Content-Type': 'application/json',
                };
                if (devJwt) {
                    headers.Authorization = "Bearer ".concat(devJwt);
                }
                return [2 /*return*/, fetch('/api/v1/users/current_user/sse', {
                        method: 'POST',
                        headers: headers,
                    })
                        .then(function (x) { return x.json(); })
                        .then(function (result) { return result.jwt; })
                        .then(function (token) {
                        debug('Retrieved SSE token from API');
                        localStorage.setItem('sse', JSON.stringify({
                            key: token,
                            timestamp: currentTime,
                        }));
                        return token;
                    })
                        .catch(function () {
                        debug('Not signed in, returning undefined');
                        return undefined;
                    })];
            });
        });
    };
    return ServerExecutor;
}());
export { ServerExecutor };
